.one,
.two,
.three,
.four,
.five {
  border: none;
  border-radius: 4px;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.48);
  overflow: hidden;
  padding: 10px 50px;
  font-size: 20px;
  position: relative;
  color: white;
  outline: none;
  cursor: pointer;
  width: 100%;
  background-size: 110%;
  -webkit-transition: box-shadow 0.4s;
  transition: box-shadow 0.4s;
}

.one:hover,
.two:hover,
.three:hover,
.four:hover,
.five:hover {
  box-shadow: 0px 0px 30px rgba(255, 255, 255, 0.2);
}

.one:before,
.two:before,
.three:before,
.four:before,
.five:before,
.one:after,
.two:after,
.three:after,
.four:after,
.five:after {
  font-family: FontAwesome;
  display: block;
  position: absolute;
}

.one:before,
.two:before,
.three:before,
.four:before,
.five:before {
  transition: all 1s;
  font-size: 25px;
  left: 5px;
  top: 10px;
}

.one:after,
.two:after,
.three:after,
.four:after,
.five:after {
  -webkit-transition: right 0.4s, -webkit-transform 0.2s;
  transition: right 0.4s, -webkit-transform 0.2s;
  transition: right 0.4s, transform 0.2s;
  font-size: 100px;
  opacity: 0.3;
  right: -120px;
  top: -17px;
}

.five {
  background-image: linear-gradient(to bottom, #5027e9, rgba(154, 40, 87, 0.24)),
    url("../../images/buyTickit.jpg");
  background-size: cover;
  background-position: 0% 0%;
  animation: backgroundSlide 8s infinite linear; 
}

.five:hover:after {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.five:hover:before {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.five:after,
.five:before {
  content: "🎫";
}

.five b {
  color: #f764ff;
  font-weight: 700;
}

@keyframes backgroundSlide {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%; 
  }
  100% {
    background-position: 0% 0%; 
  }
}


@media (max-width: 768px) {
  .one,
  .two,
  .three,
  .four,
  .five {
    padding: 8px 30px; 
    font-size: 16px; 
  }
  .five:before {
    transition: all 1s;
    font-size: 12px;
    left: 5px;
    top: 6px;
  }
}

@media (max-width: 480px) {
  .one,
  .two,
  .three,
  .four,
  .five {
    padding: 6px 30px; 
    font-size: 14px; 
  }

  .five:before {
    transition: all 1s;
    font-size: 12px;
    left: 5px;
    top: 6px;
  }
}
@media (max-width: 400px) {
  .one,
  .two,
  .three,
  .four,
  .five {
    padding: 3px 20px;
    font-size: 10px; 
    font-weight: 500;
  }

  .five:before {
    transition: all 1s;
    font-size: 10px;
    left: 5px;
    top: 3px;
  }
}

.snake-button {
  position: relative;
  display: inline-block;
  padding: 30px 60px;
  font-size: 30px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
  color: #1670f0;
  background: none;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.snake-button::before,
.snake-button::after {
  content: "";
  position: absolute;
  background: rgba(255, 255, 255, 0.05);
}

.snake-button::before {
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
}

.snake-button::after {
  background: none;
}


.snake-button::before {
  background: rgba(255, 255, 255, 0.05);
}

.snake-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  box-sizing: border-box;
  animation: snakeBorder 4s linear infinite;
}

@keyframes snakeBorder {
  0% {
    border-top-color: #1779ff;
  }
  25% {
    border-right-color: #1779ff;
  }
  50% {
    border-bottom-color: #1779ff;
  }
  75% {
    border-left-color: #1779ff;
  }
  100% {
    border-top-color: #1779ff;
  }
}
