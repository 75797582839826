.root {
  overflow: hidden;
}

.z-max {
  z-index: 999999;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hiddenScrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hiddenScrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.customScrollBar {
  /* width */
  &::-webkit-scrollbar {
    height: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

//

.nc-will-change-top {
  will-change: top;
}
//

.nc-SectionClientSay .glide__bullet--active {
  @apply bg-neutral-700;
}
.nc-GallerySlider .glide__bullet--active {
  @apply bg-white w-2 h-2;
}

.ProductDetailModalPhotos .glide__bullet--active {
  @apply block;
}

//
.nc-card-title {
  @apply transition-colors  hover:text-primary-900 dark:hover:text-primary-300 duration-75;
}

//
.menuIsNew_lv1 {
  > a::before {
    content: "New!";
    position: absolute;
    top: -2px;
    right: 0;
    display: inline-block;
    padding: 0 5px;
    line-height: 15px;
    border-radius: 4px;
    background-color: #f35656;
    color: #fff;
    font-size: 10px;
    font-weight: normal;
  }
}
.menuIsNew {
  > a::after {
    content: "New!";
    display: inline-block;
    padding: 0 5px;
    line-height: 15px;
    border-radius: 4px;
    background-color: #f35656;
    color: #fff;
    font-size: 10px;
    margin-left: 6px;
  }
}

//
.nc-PostCardCommentBtn,
.nc-PostCardLikeAction,
.nc-CommentCardLikeReply > button {
  &:hover {
    span {
      color: inherit;
    }
  }
}
.explore-img {
  transition: 0.3s ease-in-out;
}
.explore-img:hover {
  border-radius: 50% !important;
}
//
.nc-box-has-hover {
  @apply bg-white rounded-3xl border border-neutral-200/70 hover:bg-neutral-50 hover:shadow-xl hover:border-transparent transition-shadow;
}

.nc-dark-box-bg-has-hover {
  @apply dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:hover:bg-neutral-800 dark:hover:shadow-2xl;
}

.nc-dark-box-bg {
  @apply dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:shadow-2xl;
}

//
.nc-origin-100 {
  transform-origin: 100% 50% 0px;
}
.nc-origin-50 {
  transform-origin: 50% 50% 0px;
}

// HERO
.nc-hero-field-padding {
  @apply py-3 px-4 md:py-4 md:px-6 xl:py-6 xl:px-8;
}
.nc-hero-field-focused {
  @apply shadow-2xl rounded-full dark:bg-white/5;
}

.nc-icon-field {
  @apply w-5 h-5 lg:w-7 lg:h-7;
}
//
button.gm-control-active.gm-fullscreen-control {
  @apply rounded-xl #{!important};
}

//
.nc-FiveStartIconForRate {
  svg:hover ~ svg {
    @apply text-neutral-300;
  }
}
/* 
 */
.nc-shadow-lg {
  box-shadow: 2px 8px 40px rgba(0, 0, 0, 0.08);
}
.hover\:nc-shadow-lg:hover {
  box-shadow: 2px 8px 40px rgba(0, 0, 0, 0.08);
}

.nc-shadow-sketch {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.hover\:nc-shadow-sketch:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.nc-p-l-container {
  padding-left: 16px;
  @screen sm {
    padding-left: calc((100vw + 32px - 640px) / 2);
  }
  @screen md {
    padding-left: calc((100vw + 32px - 768px) / 2);
  }
  @screen lg {
    padding-left: calc((100vw + 32px - 1024px) / 2);
  }
  @screen xl {
    padding-left: calc((100vw + 80px - 1280px) / 2);
  }
  @screen 2xl {
    padding-left: calc((100vw + 256px - 1536px) / 2);
  }
}

.nc-p-r-container {
  padding-right: 16px;
  @screen sm {
    padding-right: calc((100vw + 32px - 640px) / 2);
  }
  @screen md {
    padding-right: calc((100vw + 32px - 768px) / 2);
  }
  @screen lg {
    padding-right: calc((100vw + 32px - 1024px) / 2);
  }
  @screen xl {
    padding-right: calc((100vw + 80px - 1280px) / 2);
  }
  @screen 2xl {
    padding-right: calc((100vw + 256px - 1536px) / 2);
  }
}

// LISTING PAGE DETAIL
.listingSection__wrap--old {
  @apply w-full flex flex-col sm:rounded-2xl border-b sm:border-t sm:border-l sm:border-r border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 pb-10 px-0 sm:p-4 xl:p-8;
}

.listingSectionSidebar__wrap {
  @apply w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 sm:pb-10 px-0 sm:p-4 xl:p-8;
}

.listingSection__wrap {
  @apply w-full flex flex-col border-b border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 pb-14;
}

//
input[type="time"]::-webkit-calendar-picker-indicator {
  @apply dark:bg-neutral-500 rounded-md;
}

[type="button"] {
  -webkit-appearance: none !important;
}

//
.nc-product-notify-animation {
  animation-name: ncSlideLeft;
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
}

.nc-product-notify-animation-out {
  animation-name: ncSlideRight;
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
}

@keyframes ncSlideLeft {
  from {
    transform: translateX(200px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes ncSlideRight {
  from {
    transform: translateX(0px);
    opacity: 1;
  }
  to {
    transform: translateX(200px);
    opacity: 0;
  }
}

@keyframes CardLarge1ExampleRight {
  from {
    transform: scale(1.05) translateY(20px);
    opacity: 0;
  }
  to {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}

@keyframes CardLarge1ExampleLeft {
  from {
    transform: translateX(-50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes SectionHero2ItemLeft {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes SectionHero2ItemDot {
  from {
    width: 30px;
    opacity: 0;
  }
  to {
    width: 80px;
    opacity: 1;
  }
}

/* == */
.nc-SectionHero2Item--animation {
  .nc-SectionHero2Item__dot {
    animation-name: SectionHero2ItemDot;
    animation-duration: 150ms;
    animation-timing-function: linear;
  }

  .nc-SectionHero2Item__image {
    animation-name: CardLarge1ExampleRight;
    animation-duration: 650ms;
    animation-timing-function: ease-in-out;
  }

  .nc-SectionHero2Item__left {
    animation-name: SectionHero2ItemLeft;
    animation-duration: 600ms;
    animation-timing-function: ease-in-out;
  }

  .nc-SectionHero2Item__button {
    animation-name: CardLarge1ExampleLeft;
    animation-duration: 800ms;
    animation-timing-function: ease-in-out;
  }
  .nc-SectionHero2Item__heading {
    animation-name: CardLarge1ExampleLeft;
    animation-duration: 600ms;
    animation-timing-function: ease-in-out;
  }
  .nc-SectionHero2Item__subheading {
    animation-name: CardLarge1ExampleLeft;
    animation-timing-function: ease-in-out;
    animation-duration: 400ms;
  }
}


//upprcase
.uppercase-first-letter::first-letter {
  text-transform: uppercase;
}

// custom Styles  For WEB

.ticketBtn {
  background: var(--themePrimaryColor);
  color: #fff;
  cursor: pointer;
  border-radius: 2px;
  padding: 10px 16px;
  text-decoration: none;
  position: relative;
  transition: all 0.5s;
}
.ticketBtn:hover {
  background-color: rgb(88, 88, 225);
}
.ticketBtn:after,
.ticketBtn:before {
  content: "";
  position: absolute;
  background-color: #fff;
  width: 15px;
  height: 15px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
}

.ticketBtn:before {
  left: -8%;
}
.ticketBtn:after {
  right: -7%;
}
.categories-card {
  transition: transform 0.5s;
}
.categories-card:hover {
  transform: scale(1.1);
}
.Become-organizer {
  background-image: url(../Become-org.svg);
}
.GarbaCoupleImg {
  filter: drop-shadow(0 0 0.5rem black);
  transition: all 0.3s ease-in-out;
}
.GarbaCoupleImg:hover {
  filter: drop-shadow(0 0 0.5rem rgb(225, 150, 220));
  transform: translateY(-15px);
}
.organize-btn {
  border-radius: 4px;
  color: #fff;
  background-image: linear-gradient(135deg, #feb692, #ea5455);
  box-shadow: 0 20px 30px -6px hsla(3, 81%, 66%, 0.5);
  outline: none;
  cursor: pointer;
  border: none;
  padding: 10px;
  transition: all 0.3s ease-in-out;
  display: inline-block;
}
.organize-btn:hover {
  box-shadow: none;
}
@media (max-width: 320px) {
  .organize-btn {
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
@media (max-width: 768px) {
  .organize-btn {
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.truncate-venue {
  height: 4rem; /* Adjust as needed */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Adjust the number of lines to show */
  -webkit-box-orient: vertical;
}

/* Hide scrollbar for WebKit browsers */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.hide-scrollbar {
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for IE and Edge */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
}

.event-hero-background {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-item-1 {
  background-image: url("../Assets/Ekka-Ras-garba ground.jpg");
}

.bg-item-2 {
  background-image: url("../Assets/orcheta_list_2024.png");
}

.bg-item-3 {
  background-image: url("../Assets/Eka-ras-garba-Location-img.jpg");
}

@keyframes salePulse {
  from {
    background-color: #a00;
  }
  50% {
    background-color: #c00;
    transform: scale(1.1);
  }
  to {
    background-color: #a00;
  }
}

mark.parking-free {
  background: #c00; // fallback for non-webkit browsers
  display: block; // or it won't work
  width: 150px;
  text-align: center;
  line-height: 30px;
  border-radius: 3em;
  color: #fff;
  font-weight: bold;
  animation: salePulse 1s infinite;
  margin: 0 auto;
}

//Toast container style

@keyframes bounceIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

.bounce-in {
  animation: bounceIn 0.5s ease-out;
}

html,
body {
  overflow: auto; /* Ensure scrolling is allowed */
}

.custom-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust to fit your needs */
}

.custom-loader {
  width: 50px;
  height: 50px;
  background-color: #3498db;
  border-radius: 50%;
  animation: bounce 1.5s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.Buybtn {
  animation: shake 2s infinite;
  width: 125px;
  height: 45px;
  border: none;
  border-radius: 10px;
  background-color: #f74b8d;
  color: #fff;
  font-size: 16px;
  font-family: "Lucida Sans";
}
@keyframes shake {
  10% {
    transform: scale(1.05) rotate(10deg);
  }
  20% {
    transform: scale(1.05) rotate(-10deg);
  }
  30% {
    transform: scale(1.05) rotate(10deg);
  }
  40% {
    transform: none;
  }
  from {
    background-color: #f74b8d;
  }
  to {
    background-color: blue;
  }
  10% {
    font-size: 18px;
  }
}

.garbaIcardbtn {
  animation: shake 2s infinite;
  width: 200px;
  height: 45px;
  border: none;
  border-radius: 10px;
  background-color: #f74b8d;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-family: "Lucida Sans";
}
@keyframes shake {
  10% {
    transform: scale(1.05) rotate(10deg);
  }
  20% {
    transform: scale(1.05) rotate(-10deg);
  }
  30% {
    transform: scale(1.05) rotate(10deg);
  }
  40% {
    transform: none;
  }
  from {
    background-color: #f74b8d;
  }
  to {
    background-color: blue;
  }
  10% {
    font-size: 18px;
  }
}
@media (max-width: 321px) {
  .garbaIcardbtn {
    width: 150px;
    height: 45px;
    font-size: 10px;
  }
}

@keyframes butybtnamination {
  from {
    background-color: #4a90e2; /* Start color */
    box-shadow: 0 0 0 rgba(0, 0, 0, 0); /* No shadow */
  }
  50% {
    background-color: #357abd; /* Intermediate color */
    transform: scale(1.1); /* Slightly larger scale */
    box-shadow: 0 0 10px #357abd; /* Glowing border effect */
  }
  to {
    background-color: #4a90e2; /* End color */
    box-shadow: 0 0 0 rgba(0, 0, 0, 0); /* No shadow */
  }
}
@keyframes buyFilp {
  from {
    background-color: #4a90e2; /* Start color */
    box-shadow: 0 0 0 rgba(0, 0, 0, 0); /* No shadow */
    transform: rotateX(0); /* Initial state (no flip) */
  }
  50% {
    background-color: #357abd; /* Intermediate color */
    transform: scale(1.1) rotateX(180deg); /* Flip and slightly larger scale */
    box-shadow: 0 0 10px #357abd; /* Glowing border effect */
  }
  to {
    background-color: #4a90e2; /* End color */
    box-shadow: 0 0 0 rgba(0, 0, 0, 0); /* No shadow */
    transform: rotateX(360deg); /* Complete vertical flip */
  }
}

.buybtn {
  background: #4a90e2; // fallback for non-webkit browsers
  display: block; // or it won't work
  width: 150px;
  text-align: center;
  line-height: 30px;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
  animation: butybtnamination 2s infinite;
  margin: 0 auto;
}

.icardBtn {
  background: #c00; // fallback for non-webkit browsers
  display: block; // or it won't work
  width: 200px;
  text-align: center;
  line-height: 40px;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
  animation: butybtnamination 2s infinite;
  margin: 0 auto;
}
@media (max-width: 450px) {
  .icardBtn {
    width: 140px;
    height: 30px;
    font-size: 10px;
    line-height: 10px;
  }
  .buybtn {
    width: 100px;
    height: 30px;
    font-size: 10px;
    line-height: 10px;
  }
}

/// start buy btn

.animated-button1 {
  background: linear-gradient(-30deg, #3d0b0b 50%, #2b0808 50%);
  padding: 10px 20px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  overflow: hidden;
  color: #fff;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button1::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ad8585;
  opacity: 0;
  -webkit-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}

.animated-button1:hover::before {
  opacity: 0.2;
}

.animated-button1 span {
  position: absolute;
}

.animated-button1 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(43, 8, 8, 0)),
    to(#d92626)
  );
  background: linear-gradient(to left, rgba(43, 8, 8, 0), #d92626);
  -webkit-animation: 2s animateTop linear infinite;
  animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.animated-button1 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(43, 8, 8, 0)),
    to(#d92626)
  );
  background: linear-gradient(to top, rgba(43, 8, 8, 0), #d92626);
  -webkit-animation: 2s animateRight linear -1s infinite;
  animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.animated-button1 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(43, 8, 8, 0)),
    to(#d92626)
  );
  background: linear-gradient(to right, rgba(43, 8, 8, 0), #d92626);
  -webkit-animation: 2s animateBottom linear infinite;
  animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.animated-button1 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(43, 8, 8, 0)),
    to(#d92626)
  );
  background: linear-gradient(to bottom, rgba(43, 8, 8, 0), #d92626);
  -webkit-animation: 2s animateLeft linear -1s infinite;
  animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

@media (max-width: 460px) {
  .animated-button1 {
    padding: 5px 10px;
    font-size: 20px;
  }
}
@media (max-width: 375px) {
  .animated-button1 {
    padding: 5px 8px;
    font-size: 10px;
    font-weight: 300;
  }
}

// end buy btn
.wrapper {
  display: flex;
  justify-content: center;
}

.cta {
  display: flex;
  align-items: center;
  padding: 10px 20px; /* Smaller padding */
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 16px; /* Smaller font size */
  color: white;
  background: #6225e6;
  transition: 0.5s;
  box-shadow: 4px 4px 0 black; /* Smaller shadow */
  transform: skewX(-15deg);
  position: relative;
  overflow: hidden;
  animation: ctaAnimation 2s infinite;
}

.cta:focus {
  outline: none;
}

.cta span.icon {
  margin-left: 10px; /* Spacing between text and icon */
}

.icon svg {
  fill: white; /* Icon color */
  width: 24px; /* Icon width */
  height: 24px; /* Icon height */
  transition: 0.5s;
}

/* Responsive styling */
@media (max-width: 449px) {
  /* Small devices (phones) */
  .cta {
    padding: 5px 10px;
    font-size: 8px;
  }

  .icon svg {
    width: 20px;
    height: 20px;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  /* Medium devices (tablets) */
  .cta {
    padding: 10px 18px;
    font-size: 15px;
  }

  .icon svg {
    width: 22px;
    height: 22px;
  }
}

@media (min-width: 769px) {
  /* Large devices (desktops) */
  .cta {
    padding: 10px 20px;
    font-size: 16px;
  }

  .icon svg {
    width: 24px;
    height: 24px;
  }
}

/* Keyframes for animations */
@keyframes ctaAnimation {
  0% {
    box-shadow: 4px 4px 0 black;
    transform: skewX(-15deg);
  }
  50% {
    box-shadow: 8px 8px 0 #fbc638;
    transform: skewX(-15deg) translateX(2px) translateY(2px);
  }
  100% {
    box-shadow: 4px 4px 0 black;
    transform: skewX(-15deg);
  }
}

.buyBtnAnimation {
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.16) inset,
    0px 0px 10px 0px #de3964;

  // background-image: linear-gradient(to bottom, #de3964, rgba(154, 40, 87, 0.24)),
  //   url("https://t3.ftcdn.net/jpg/04/61/47/86/360_F_461478612_PHMTifSGUIWDHjkYau6jgW2GJlzcml9I.jpg");

  background-image: linear-gradient(to bottom, #de3964, rgba(154, 40, 87, 0.24)),
    url("../images/buyTickit.jpg");

  &:hover {
    box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.16) inset,
      0px 0px 30px 0px #df3964;

    &:after {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    &:before {
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
  }

  &:after,
  &:before {
    content: "🎫";
  }

  b {
    color: #f764ff;
    font-weight: 700;
  }
}


// loading overly
.custom-loader-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6); /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; /* Ensure it's on top of other content */
}

.custom-loader {
  z-index: 20; /* Ensure the spinner is visible above the background */
}

//spin

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animate-spin {
  border-radius: 24px;
  animation: spin 10s linear infinite !important;
}
//show detail
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the icon */
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

