.tabs {
}

.tabs-titles {
  list-style: none;
  padding: 5px;
  margin: 0;
  /* background-color: #eeeff1; */
}

.tab-title {
  background-color: #eeeff1;
  display: inline-block;
  padding: 10px;
  color: #c7c6c2;
  cursor: pointer;
  margin-left: 5px;
  margin-top: 2px;
}

.tab-title--active {
  background-color: #dee2e6;
  color: #00070a;
  font-weight: 700;
}

.tab-content-add {
  background-color: #dee2e6;
  padding: 10px 22px;
  margin: 0;
}
